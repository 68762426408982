<template>
    <v-dialog v-model="dialog" :persistent="uploading" width="500">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" v-on="on"  elevation="1"><v-icon class="mr-2">mdi-image-plus</v-icon>Добавить</v-btn>
        </template>

        <v-card>
            <v-card-title class="headline grey lighten-2">
                Загрузка изображения
            </v-card-title>
            <v-card-text>
                {{folder}}
                <v-file-input
                        accept="image/png, image/jpeg, image/bmp"
                        chips
                        :small-chips="images.length >4"
                        :show-size="1000"
                        multiple
                        counter
                        show-size
                        prepend-icon="mdi-camera"
                        v-model="images"
                        class="mb-8"
                        v-if="!uploading"
                ></v-file-input>

                <div  v-for="(image, index) in imagesStatus">
                    {{index+1}} {{image.name}} {{image.status}}
                    <v-progress-linear :value="image.uploadValue"></v-progress-linear>
                </div>

                <v-btn color="primary" v-if="images.length >0 && !uploading" v-on:click="uploadImage()" block elevation="1"><v-icon class="mr-2">mdi-upload</v-icon>Загрузить</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {storage} from "../../firebase";

    export default {
        name: "MediaImageUploader",
        data() {
            return {
                dialog: false,
                rules: [
                    value => !value || value.size < 2000000 || 'Изображение должно быть не больше 2 MB!',
                ],
                images: [],
                imagesStatus: [],

                uploading: false,
                imageSizes: this.$store.state.imageSizes
            }
        },
        props: ['value', 'folder'],

        methods: {
            uploadImage() {

                const that = this;
                that.uploading = true;


                Promise.all(
                    that.images.map((image, index) => {
                        that.imagesStatus.push({name: image.name, status: 'Загрузка', uploadValue: 0})

                        const spitedName = that.getFilenameAndExtension(image.name)
                        const imageName = spitedName[0] + '-' + that.random(6) + '.' + spitedName[1]
                        const storageRef = storage.ref( that.folder + '/' + imageName);
                        return new Promise(resolve => {
                            const task = storageRef.put(image);
                            task
                                .on(`state_changed`, snapshot => {
                                        that.imagesStatus[index].uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                    }, error => {
                                        resolve()
                                        that.imagesStatus[index].status = 'Не заружено!';
                                    },
                                    () => {
                                        that.imagesStatus[index].status = 'Оптимизация...';
                                        this.uploadValue = 100;
                                        Promise.all(
                                            that.imageSizes.map(size => {
                                                return new Promise(resolve1 => {
                                                    setTimeout(getOptimizedImg, 3000, size, resolve1);


                                                    let imgSizes = [];
                                                    function getOptimizedImg(size, resolve1) {
                                                        const thumbnail = that.getFilenameAndExtension(imageName)[0]+'_'+size+'.'+that.getFilenameAndExtension(imageName)[1];
                                                        let pathReference = storage.ref(that.folder + '/' + thumbnail);
                                                        pathReference.getDownloadURL()
                                                            .then((url) => {
                                                                imgSizes.push({url: url, name: thumbnail, path: pathReference.fullPath})
                                                                // const img = {url: url, name: thumbnail, path: pathReference.fullPath}

                                                                that.$emit('uploadedImage', imgSizes);
                                                                that.imagesStatus[index].status = 'Загружено';
                                                                resolve1()
                                                            }).catch((e) => {
                                                            setTimeout(getOptimizedImg, 3000, size, resolve1);
                                                        });
                                                    }
                                                })
                                            })
                                        )
                                            .then(res => resolve())
                                            .catch(e => resolve())

                                    });

                        });
                    })
                )
                    .then((url) => {
                        that.dialog = false
                        that.uploading = false;
                        that.rules = false;
                        that.images = [];
                        that.imagesStatus = [];
                        that.$store.commit('alert', { message: 'Изображения заружены!'});
                    })
                    .catch((error) => {
                        that.uploading = false;
                        that.rules = false;
                        that.images = [];
                        this.$store.commit('alert', { message: 'Упс, ошибка!', type: 'error'});
                    });
            },

        },

    }
</script>

<style scoped>

</style>

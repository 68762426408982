<template>
    <div>

        <!--        {{uploadedImage}}-->
<!--        <br>-->
<!--        {{images}}-->
        <v-row no-gutters fluid class="spacing-playground my-6 p-0">
            <v-col cols="12" sm="8">
                <div class="text-sm-h4 mb-5">{{breadcrumbs[breadcrumbs.length - 1].alternativeName ? breadcrumbs[breadcrumbs.length - 1].alternativeName : breadcrumbs[breadcrumbs.length - 1].name}}</div>
                <div style="margin-top: -16px">
                    <div class="breadcrumbs" v-for="(item, index) in breadcrumbs">
                        <span>/</span>
                        <span v-if="breadcrumbs.length-1 === index"><div class="v-breadcrumbs__item"> {{item.alternativeName ? item.alternativeName : item.name}}</div></span>
                        <span v-else><a class="v-breadcrumbs__item" v-on:click="goMedia(item.name, item.path)"> {{item.name}}</a></span>
                    </div>
                </div>
<!--                <div v-else v-on:click="images = null; currentFolderName = null" class="text-sm-h4 pointer mb-5"><span>←</span> {{currentFolderName}}</div>-->
            </v-col>
            <v-col cols="12" sm="4" align="right">
                <MediaImageUploader v-on:uploadedImage="uploadedImage" :folder="breadcrumbs[breadcrumbs.length-1].path" />

            </v-col>
        </v-row>
        <v-progress-linear indeterminate v-if="!(folders.length > 0 || images.length > 0)"></v-progress-linear>


        <v-card outlined>
            <v-card-text>
                <v-row dense>
                    <v-col v-for="folder in folders" v-on:click="goMedia(folder.name, folder.path, folder.alternativeName)" cols="2">
                        <v-card elevation="0" class="pointer">
                            <v-img src="../assets/folder.svg"></v-img>
                            <v-card-subtitle class="pt-0" style="text-align: center"><b>{{ folder.alternativeName ? folder.alternativeName : (folder.name === 'projects' || folder.name === 'pages') ? folder.name : '** id: '+folder.name+' **'}}</b><span style="font-size: 12px; margin-top: -4px; margin-left: 4px; position: absolute">{{ folder.alternativeName ? 'id:'+folder.name+''  : ''}}</span></v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
<!--                {{folders}}-->
                <v-row dense>
                    <v-col v-for="image in sortedImages" cols="2">
                        <div  elevation="0" class="pointer">
                            <div v-if="image['300x300']">
                                <v-img v-on:click="applyImg(image)" style="min-height: 60px; background-color: #F2F2F2" :src="image['300x300']['url']"></v-img>
                                <!-- image['300x300'].path  del-->
                            </div>
                            <div class="actions">
                                <div class="pl-0 pt-1" style="font-size: 12px">{{image.name}}</div>
                                <div class="link" v-on:click="deleteImage(image)">Удалить</div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
<!--        <Loader v-else/>-->


    </div>
</template>

<script>

    import { storage, db } from '../firebase';
    import MediaImageUploader from "../components/ui/MediaImageUploader";
    import Loader from "../components/ui/Loader";


    export default {
        name: "Media",
        components: {Loader, MediaImageUploader},
        data() {
          return {
              folders: [],
              images: [],
              breadcrumbs: [{name: 'Медиа', path: ''}],

          }
        },

        methods: {
            uploadedImage(uploadedImage) {
                uploadedImage.forEach(im => this.images.push(im))
            },
            deleteImage(image) {
                if (confirm('Удалить изображение - '+image.name)) {
                    const that = this;
                    let deletedStatus = [];
                    const imageSizes = this.$store.state.imageSizes;
                    imageSizes.forEach(size => {
                        const imgPath = image[size].path;
                        deleteImgFromDB(imgPath).then(res => {
                            deletedStatus.push(res)
                            const index = this.images.findIndex(image => image.path === imgPath)
                            that.images.splice(index, 1)
                            if (deletedStatus.length === imageSizes.length) {
                                that.$store.commit('alert', { message: 'Изображение удалено!'});
                            }
                        })
                    })
                }

                async function deleteImgFromDB(path) {
                    await storage.ref(path).delete().then(res => {return 'done'})
                }

            },
            goMedia(name, path, alternativeName) {
                let bc = [{name: 'Медиа', path: ''}]
                if (path !== '') {
                    let splited = path.split('/')
                    splited.map((e, index) => {
                        bc.push({name: e, path: splited.slice(0, index+1).join('/'), alternativeName: alternativeName})
                    })
                }
                this.breadcrumbs = bc;
                this.getListOfMedea(path)
            },
            getListOfMedea(path) {
              const that = this;
              this.images = [];
              this.folders = [];

              storage.ref(path).listAll()
                  .then((res) => {
                      res.prefixes.forEach((folderRef, index) => {
                          const that = this;
                          if (that.breadcrumbs.length === 2) {
                              db.collection(that.breadcrumbs[1].name).doc(folderRef.name).get().then(res => res.data().en.title)
                                  .then(res => {
                                      that.folders.push({name: folderRef.name, alternativeName: res, path: folderRef.fullPath})
                                  })
                          } else {
                              that.folders.push({name: folderRef.name, alternativeName: null, path: folderRef.fullPath})
                          }

                      });
                      res.items.forEach((itemRef) => {
                          itemRef.getDownloadURL().then(url => {
                              that.images.push({name: itemRef.name, path: itemRef.fullPath, url: url})
                          })
                      });
                  }).catch((error) => {
                  console.log('error:'+ error)
              });
            },
            applyImg(image) {
                const imageUrl = image['1600x1600']['url']
                if (parent.selectImage && parent.document.querySelector(".is-modal.imageselect.active")) {
                    parent.selectImage(imageUrl);
                }
                this.$emit('arrayOfSizes', image)
            },
        },
        computed: {
            sortedImages: function () {
                let a = {}
                this.images.map(im => {
                    // Если файл заглушка 'null.null' - пропускаем
                    if (im.name === 'null.null') return

                    const name = this.getFilenameAndExtension(im.name)[0]
                    let nameWithout = name.substring(0, name.lastIndexOf('_'));
                    let format = name.substring(name.lastIndexOf('_') +1);

                    if (!a.hasOwnProperty(nameWithout)) {
                        a[nameWithout] = {name: im.name}
                    }
                    a[nameWithout][format] = {url: im.url, path: im.path}
                })
                return Object.values(a);
            },
        },
        mounted() {
            this.getListOfMedea('')
        },
    }
</script>

<style scoped>
    .link {
        color: #1976d2;
        cursor: pointer;
        font-size: 14px;
    }

    .link:hover {
        color: red;
    }
    .pointer {
        cursor: pointer;
    }
    .actions {
        opacity: 0;
        transition: 0.3s;
    }
    .pointer:hover .actions {
        opacity: 1;
    }
    .one-line {

        display: block;/* or inline-block */
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        height: 4.6em;
        /*line-height: 1.8em;*/
    }

    .breadcrumbs span, .breadcrumbs {
        display: inline-block;
        margin-right: 8px;
    }
</style>
